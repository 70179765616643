import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const KioskApp = Loader(lazy(() => import('src/content/TerminalApp/KioskApp')));

const KioskCheckIn = Loader(
  lazy(() => import('src/content/TerminalApp/KioskApp/KioskCheckIn'))
);

const KioskInvoice = Loader(
  lazy(() => import('src/content/TerminalApp/KioskApp/KioskInvoice'))
);

const KioskPatientIntake = Loader(
  lazy(() => import('src/content/TerminalApp/KioskApp/KioskPatientIntake'))
);

const KioskBooking = Loader(
  lazy(() => import('src/content/TerminalApp/KioskApp/KioskBooking'))
);

const kioskRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <KioskApp />
  },
  {
    path: 'checkin',
    element: <KioskCheckIn />
  },

  {
    path: 'invoice',
    children: [
      {
        path: '/',
        element: <KioskInvoice />
      },
      {
        path: ':invoiceId',
        element: <KioskInvoice />
      }
    ]
  },

  {
    path: 'patient',
    children: [
      {
        path: '/',
        element: <KioskPatientIntake />
      },
      {
        path: ':patientId',
        element: <KioskPatientIntake />
      }
    ]
  },

  {
    path: 'booking',
    children: [
      {
        path: '/',
        element: <KioskBooking />
      },
      {
        path: ':appointmentId',
        element: <KioskBooking />
      }
    ]
  }

  // {
  //   path: 'customer',
  //   children: [
  //     {
  //       path: ':patientId',
  //       element: <SingleEditPatient />
  //     },
  //     {
  //       path: ':patientId/:tabName',
  //       element: <SingleEditPatient />
  //     }
  //   ]
  // }
];

export default kioskRoutes;

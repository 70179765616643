import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import TerminalAuthenticated from 'src/components/Authenticated/TerminalAuthenticated';
import KioskAuthenticated from 'src/components/Authenticated/KioskAuthenticated';
import { Navigate } from 'react-router-dom';

// import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import FlowAppLayout from 'src/layouts/FlowAppLayout';
import KioskAppLayout from 'src/layouts/KioskAppLayout';

import managementRoutes from './management';

import accountRoutes from './account';
import baseRoutes from './base';
import patientRoutes from './patients';
import customerRoutes from './customers';
import overviewRoutes from './overview';
import queueRoutes from './queue';
import invoiceRoutes from './invoices';
import inventoryRoutes from './inventory';
import settingRoutes from './settings';
import userRoutes from './users';
import communicationRoutes from './communications';
import paymentRoutes from './payments';
import calendarRoutes from './calendar';
import preferenceRoutes from './preferences';
import corporateRoutes from './corporates';
import contactRoutes from './contacts';
import serviceRoutes from './services';
import reportRoutes from './reports';
import onlineBookingRoutes from './onlineBooking';
import onlineBookingTransactionRoutes from './onlineBookingTransactions';
import onlineBookingPageRoutes from './onlineBookingPages';
import locationRoutes from './officeLocations';
import timeOffRoutes from './timeOffs';
import integrationsRoutes from './integrations';
import classServiceRoutes from './classServices';
import classAppointmentRoutes from './classAppointments';
import passRoutes from './passes';
import promoCodeRoutes from './promoCodes';
import appointmentPlanTemplatesRoutes from './appointmentPlanTemplates';
import stationRoutes from './stations';
import creditNotesRoutes from './creditNotes';
import appointmentRoutes from './appointments';

import stripeTerminalRoutes from './stripeTerminal';
import terminalRoutes from './terminal';
import flowRoutes from './flow';

import kioskRoutes from './kiosk';

import intakeRoutes from './intake';
import confirmationRoutes from './confirmation';
// import maintenanceRoutes from './maintenance';

// import MaintenancePage from 'src/content/pages/Status/Maintenance';

const router: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },

  {
    path: 'confirm',
    children: confirmationRoutes
  },

  {
    path: 'booking',
    children: onlineBookingRoutes
  },

  {
    path: 'intake',
    children: intakeRoutes
  },

  // Accent Header Layout

  {
    path: 'app',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
      // <MaintenancePage />
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        children: overviewRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      },
      {
        path: 'patients',
        children: patientRoutes
      },
      {
        path: 'customers',
        children: customerRoutes
      },
      {
        path: 'queue',
        children: queueRoutes
      },
      {
        path: 'invoices',
        children: invoiceRoutes
      },
      {
        path: 'inventory',
        children: inventoryRoutes
      },
      {
        path: 'corporates',
        children: corporateRoutes
      },
      {
        path: 'contacts',
        children: contactRoutes
      },
      {
        path: 'settings',
        children: settingRoutes
      },
      {
        path: 'preferences',
        children: preferenceRoutes
      },
      {
        path: 'users',
        children: userRoutes
      },
      {
        path: 'communications',
        children: communicationRoutes
      },
      {
        path: 'payments',
        children: paymentRoutes
      },
      {
        path: 'calendar',
        children: calendarRoutes
      },
      {
        path: 'services',
        children: serviceRoutes
      },
      {
        path: 'reports',
        children: reportRoutes
      },
      {
        path: 'locations',
        children: locationRoutes
      },
      {
        path: 'timeoffs',
        children: timeOffRoutes
      },
      {
        path: 'booking-transactions',
        children: onlineBookingTransactionRoutes
      },
      {
        path: 'booking-pages',
        children: onlineBookingPageRoutes
      },
      {
        path: 'integrations',
        children: integrationsRoutes
      },
      {
        path: 'class-services',
        children: classServiceRoutes
      },
      {
        path: 'classes',
        children: classAppointmentRoutes
      },
      {
        path: 'passes',
        children: passRoutes
      },
      {
        path: 'promo-codes',
        children: promoCodeRoutes
      },
      {
        path: 'plan-templates',
        children: appointmentPlanTemplatesRoutes
      },
      {
        path: 'stations',
        children: stationRoutes
      },
      {
        path: 'creditnotes',
        children: creditNotesRoutes
      },
      {
        path: 'stripe-terminals',
        children: stripeTerminalRoutes
      },
      {
        path: 'appointments',
        children: appointmentRoutes
      },
    ]
  },
  {
    path: 'terminal',
    element: (
      <TerminalAuthenticated>
        <AccentSidebarLayout />
      </TerminalAuthenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="terminal" replace />
      },
      {
        path: 'terminal',
        children: terminalRoutes
      }
      // {
      //   path: 'flow',
      //   children: flowRoutes
      // }
    ]
  },
  {
    path: 'flow',
    element: (
      <TerminalAuthenticated>
        <FlowAppLayout />
      </TerminalAuthenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="flow" replace />
      },
      {
        path: 'flow',
        children: flowRoutes
      }
    ]
  },
  {
    path: 'kiosk',
    element: (
      <KioskAuthenticated>
        <KioskAppLayout />
      </KioskAuthenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="kiosk" replace />
      },
      {
        path: 'kiosk',
        children: kioskRoutes
      }
    ]
  },

];

export default router;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Appointments = Loader(lazy(() => import('src/content/Appointments')));
const SingleAppointment = Loader(
  lazy(() => import('src/content/Appointments/search/AppointmentDetail'))
);
const Search = Loader(lazy(() => import('src/content/Appointments/search')));

const invoicesRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Appointments />
  },
  // {
  //   path: ':appointmentId',
  //   element: <SingleAppointment />
  // },
  {
    path: 'search',
    element: <Search />
  }
];

export default invoicesRoutes;

import { useState, FC, useEffect, useRef } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';
import { useWatch } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import {
  HookFormAutocompleteField,
  HookFormCheckboxField,
  HookFormInputField,
  HookFormMultilineInputField,
  HookFormDateTimePickerField
} from 'src/components/FormFields';
import {
  addMinutes,
  addDays,
  startOfDay,
  parseISO,
  isEqual,
  format,
  lastDayOfMonth,
  startOfMonth,
  endOfMonth,
  addMonths,
  areIntervalsOverlapping,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  differenceInMinutes,
  isAfter
} from 'date-fns';
import {
  Alert,
  TextField,
  Grid,
  Box,
  Button,
  Typography,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
import MoreTimeTwoToneIcon from '@mui/icons-material/MoreTimeTwoTone';
import {
  listFreeAppointmentBookingSlots,
  clearFreeAppointmentBookingSlots,
  getPractitionerSchedule,
  clearPractitionerSchedule
} from 'src/slices/appointments';

import {
  getPractitionerClassSchedule,
  clearPractitionerClassSchedule
} from 'src/slices/class_appointments';

import {
  getPractitionerTimeOff,
  clearPractitionerTimeOffEvents
} from 'src/slices/timeOff';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import tenantMappings from 'src/utils/tenantMappings';

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(2)};

    & .fc-license-message {
      display: none;
    }

    .fc-media-screen .fc-timegrid-now-indicator-container {
      position: unset;
    }
  
    .fc .fc-timegrid-now-indicator-line {
      border-bottom-width: 1px; 
      border-top-width: 1px;
      border-color: red;
      background-color: red;
    }
  
    // Day view
    .fc-resourceTimeGridDay-view  .fc-timegrid-now-indicator-arrow {
      position: absolute;
      border: none;
      left: unset;
      right: -6px;
      width: 12px;
      height: 12px;
      background-color: red;
      border-radius: 50%;
    }
     
    // Week view
    .fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
      display: none;
    }
  
    .fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      width: 12px;
      height: 12px;
      background-color: red;
      border-radius :50%;
    }
    .fc {

      .fc-col-header-cell {
        padding: ${theme.spacing(1)};
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover,
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: ${theme.colors.alpha.black[5]} !important;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[10]} !important;
        color: ${theme.colors.alpha.black[70]} !important;
      }

      &.fc-theme-standard td,
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }

      .fc-timegrid-slot {
        height: 30px !important
    }
    }
`
);

const dictionaryMappings = tenantMappings?.dictionaryMappings;
const appointmentMappings = tenantMappings?.appointmentMappings;

// Extract event rendering logic to a separate function
const renderEventContent = (eventInfo) => {
  const isHoliday = eventInfo.event._def.extendedProps.type === 'holiday';
  const isTimeOff = eventInfo.event._def.extendedProps.type === 'Time Off';
  const isNoShow = eventInfo.event._def.extendedProps.status === 'NoShow';
  const isPending = ['PaymentPending', 'Pending'].includes(
    eventInfo.event._def.extendedProps.status
  );

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        fontSize: '9px',
        color: isHoliday || isTimeOff ? '#f5f5f5' : 'black',
        borderLeft: isPending
          ? '4px solid red'
          : eventInfo.event._def.extendedProps.status === 'N/A'
          ? ''
          : '4px solid #72F401',
        paddingLeft: '2px'
      }}
    >
      {isHoliday ? (
        <b>{eventInfo.event.title.toUpperCase()}</b>
      ) : isTimeOff ? (
        <>
          <b>{eventInfo.event._def.extendedProps.category.toUpperCase()}</b>
          <br />
          {`${format(
            parseISO(eventInfo.event._def.extendedProps.eventStartTime),
            'HH:mm'
          )} - 
            ${format(
              parseISO(eventInfo.event._def.extendedProps.eventEndTime),
              'HH:mm'
            )}`}
          <br />
          {`${eventInfo.event._def?.title} ${eventInfo.event._def?.extendedProps.description}`}
        </>
      ) : (
        <>
          <b>{`${
            isNoShow ? '🔴' : ''
          } ${eventInfo.event.title.toUpperCase()}`}</b>
          <br />
          {`${format(
            parseISO(eventInfo.event._def.extendedProps.eventStartTime),
            'HH:mm'
          )} - 
            ${format(
              parseISO(eventInfo.event._def.extendedProps.eventEndTime),
              'HH:mm'
            )}`}
          <br />
          {eventInfo.event._def.extendedProps?.description}
        </>
      )}
    </div>
  );
};

// Form fields section component
const FormFieldsSection = ({
  control,
  errors,
  locationOptions,
  filteredPractitioners,
  serviceOptions,
  loggedInUser,
  getMappedWordToUse
}) => (
  <Grid
    container
    spacing={0}
    rowGap={1.5}
    sx={{
      px: { xs: 0.5, sm: 3 },
      py: 2
    }}
    flexDirection="column"
  >
    <Grid
      item
      container
      spacing={0}
      flexDirection="row"
      justifyContent="center"
    >
      <Grid item xs={12} sm={6} md={5}>
        <HookFormAutocompleteField
          name="locationId"
          label="Location"
          options={locationOptions}
          control={control}
          errors={errors}
          rules={{ required: 'Location is required.' }}
          notClearable={true}
        />
      </Grid>
    </Grid>

    <Grid
      item
      container
      spacing={0}
      flexDirection="row"
      justifyContent="center"
    >
      <Grid item xs={12} sm={6} md={5}>
        <HookFormAutocompleteField
          name="practitionerId"
          label={getMappedWordToUse('Practitioner')}
          options={[...filteredPractitioners, { label: '', value: null }]}
          control={control}
          errors={errors}
          rules={{
            required: `${getMappedWordToUse('Practitioner')} is required.`
          }}
          notClearable={true}
          disabled={loggedInUser?.type?.toLowerCase() === 'practitioner'}
        />
      </Grid>
    </Grid>

    <Grid
      item
      container
      spacing={0}
      flexDirection="row"
      justifyContent="center"
    >
      <Grid item xs={12} sm={6} md={5}>
        <HookFormAutocompleteField
          name="serviceId"
          label="Service"
          options={serviceOptions}
          control={control}
          errors={errors}
          rules={{
            required: 'Service is required.'
          }}
          notClearable={false}
        />
      </Grid>
    </Grid>

    <Grid item xs={12} md={12}>
      <Divider sx={{ my: 0 }} />
    </Grid>
  </Grid>
);

// Calendar section component
const CalendarSection = ({
  dateValue,
  reducedHoursShown,
  setReducedHoursShown,
  calendarRef,
  providerSchedule,
  practitionerClassSchedule,
  practitionerTimeOffEvents,
  isMobile
}) => {
  const reducedHours = ['07:00', '22:30'];

  return (
    <Grid container spacing={0} rowGap={1.5}>
      <Grid item xs={12}>
        <Tooltip
          arrow
          placement="top"
          title={
            reducedHoursShown ? 'Show more timeslots' : 'Show less timeslots'
          }
          sx={{ ml: 1, mb: -3 }}
        >
          <IconButton
            color="primary"
            onClick={() => setReducedHoursShown(!reducedHoursShown)}
          >
            <MoreTimeTwoToneIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={12}>
        {dateValue && (
          <FullCalendarWrapper>
            <FullCalendar
              allDayMaintainDuration
              initialDate={dateValue}
              droppable={false}
              editable={false}
              eventDisplay="block"
              slotMinTime={reducedHoursShown ? reducedHours[0] : '00:00'}
              slotMaxTime={reducedHoursShown ? reducedHours[1] : '24:00'}
              dayMaxEventRows={4}
              eventResizableFromStart={false}
              events={[
                ...providerSchedule.filter(
                  (event) => !(event?.status === 'Cancelled')
                ),
                ...practitionerClassSchedule.filter(
                  (event) => !(event?.status === 'Cancelled')
                ),
                ...practitionerTimeOffEvents
              ]}
              eventContent={renderEventContent}
              headerToolbar={false}
              height={'auto'}
              eventMinHeight={30}
              ref={calendarRef}
              rerenderDelay={10}
              weekends
              nowIndicator={true}
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
              views={{
                gridThreeDay: {
                  type: 'timeGrid',
                  duration: { days: 3 },
                  buttonText: '3 day',
                  dayHeaderContent: (args) => format(args.date, 'EEE, dd MMM')
                },
                gridOneDay: {
                  type: 'timeGrid',
                  duration: { days: 1 },
                  buttonText: '1 day',
                  dayHeaderContent: (args) => format(args.date, 'EEE, dd MMM')
                }
              }}
              initialView={isMobile ? 'gridOneDay' : 'gridThreeDay'}
            />
          </FullCalendarWrapper>
        )}
      </Grid>
    </Grid>
  );
};

// Time slots component
const TimeSlots = ({
  sgFreeSlots,
  dateValue,
  selectedApptStartTime,
  selectedApptEndTime,
  setValue,
  getValues,
  serviceOptions,
  isMobile
}) => (
  <Box sx={{ maxHeight: '160px', overflow: 'auto' }}>
    {sgFreeSlots
      .filter((dayValue) =>
        isEqual(startOfDay(dayValue.startTime), startOfDay(dateValue))
      )
      .map((timeslot, index) => (
        <Button
          size="small"
          key={format(timeslot.startTime, 'ddMMMyyyy') + index}
          variant={
            isEqual(timeslot.startTime, selectedApptStartTime) &&
            isEqual(timeslot.endTime, selectedApptEndTime)
              ? 'contained'
              : 'outlined'
          }
          sx={{ m: 0.25, p: 0.75 }}
          onClick={() => {
            const serviceDuration =
              serviceOptions.find(
                (service) => service.value === getValues('serviceId')
              )?.duration || 60;

            setValue('appointmentStartTime', timeslot.startTime);
            setValue(
              'appointmentEndTime',
              addMinutes(timeslot.startTime, serviceDuration)
            );
          }}
        >
          {format(timeslot.startTime, 'hh:mm a')}
        </Button>
      ))}

    {sgFreeSlots.length === 0 && (
      <Typography variant="h4" sx={{ p: isMobile ? 1 : 3 }}>
        Select a service or change the calendar month.
      </Typography>
    )}
  </Box>
);

// Appointment details section
const AppointmentDetailsSection = ({
  dateValue,
  setDateValue,
  editFirstTouch,
  setEditFirstTouch,
  calendarRef,
  sgFreeSlots,
  selectedApptStartTime,
  selectedApptEndTime,
  selectedRecurFrequency,
  shouldDisableDate,
  onSearchSubmit,
  control,
  errors,
  getValues,
  setValue,
  serviceOptions,
  rescheduleOption,
  fromCalendar,
  isMobile,
  tenantIdLowerCase,
  appointmentMappings,
  getMappedWordToUse
}) => (
  <Grid
    container
    spacing={1}
    rowGap={1.5}
    sx={{ px: { xs: 0.5, sm: 3 }, py: 2, justifyContent: 'center' }}
  >
    <Grid item xs={12} md={12}>
      <StaticDatePicker
        disabled={Boolean(getValues('serviceId') === null)}
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={dateValue}
        onChange={(newValue) => {
          const changedValue =
            editFirstTouch && (rescheduleOption || fromCalendar)
              ? selectedApptStartTime
              : newValue;

          setDateValue(changedValue);

          const calItem = calendarRef.current;
          calItem.getApi().gotoDate(changedValue);

          const serviceDuration =
            getValues('serviceId') &&
            serviceOptions.find(
              (service) => service.value === getValues('serviceId')
            )
              ? serviceOptions.find(
                  (service) => service.value === getValues('serviceId')
                ).duration
              : 60;

          if (getValues('serviceId') && !editFirstTouch) {
            const newStartDateTime = setMinutes(
              setHours(
                changedValue,
                getHours(getValues('appointmentStartTime'))
              ),
              getMinutes(getValues('appointmentStartTime'))
            );
            setValue('appointmentStartTime', newStartDateTime);
            setValue(
              'appointmentEndTime',
              addMinutes(newStartDateTime, serviceDuration)
            );
          } else if (getValues('serviceId') && editFirstTouch) {
            const newStartDateTime = setMinutes(
              setHours(
                changedValue,
                getHours(getValues('appointmentStartTime'))
              ),
              getMinutes(getValues('appointmentStartTime'))
            );
            setValue('appointmentStartTime', newStartDateTime);
            setValue('appointmentEndTime', selectedApptEndTime);
          }

          if (editFirstTouch && rescheduleOption) {
            setEditFirstTouch(false);
          }
        }}
        onMonthChange={(monthStart) => {
          onSearchSubmit(monthStart);
        }}
        renderInput={(params) => <TextField {...params} />}
        shouldDisableDate={shouldDisableDate}
        minDate={new Date()}
        maxDate={new Date().setMonth(new Date().getMonth() + 3)}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <TimeSlots
        sgFreeSlots={sgFreeSlots}
        dateValue={dateValue}
        selectedApptStartTime={selectedApptStartTime}
        selectedApptEndTime={selectedApptEndTime}
        setValue={setValue}
        getValues={getValues}
        serviceOptions={serviceOptions}
        isMobile={isMobile}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <HookFormDateTimePickerField
        name="appointmentStartTime"
        label="Appointment Start Time"
        control={control}
        errors={errors}
        rules={{
          required: 'Appointment Start Time is a required field.',
          validate: (v) => v > 0 || 'Please enter a valid time.'
        }}
        disabled={false}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <HookFormDateTimePickerField
        name="appointmentEndTime"
        label="Appointment End Time"
        control={control}
        errors={errors}
        rules={{
          required: 'Appointment End Time is a required field.',
          validate: (v) =>
            (v > 0 &&
              v > getValues('appointmentStartTime') &&
              isEqual(
                startOfDay(v),
                startOfDay(getValues('appointmentStartTime'))
              )) ||
            'End time must be same day and after start time.'
        }}
        disabled={false}
      />
      {getValues('serviceId') &&
        differenceInMinutes(selectedApptEndTime, selectedApptStartTime) !==
          serviceOptions.find(
            (service) => service.value === getValues('serviceId')
          )?.duration && (
          <Alert variant="outlined" severity="error" sx={{ mt: 1 }}>
            <Typography variant="subtitle2" color="red">
              Selected time period is not equal to service duration
            </Typography>
          </Alert>
        )}
    </Grid>

    <Grid item xs={12} md={12}>
      <HookFormMultilineInputField
        name="appointmentNote"
        label="Appointment Notes (Internal)"
        control={control}
        errors={errors}
        rules={{
          required: false
        }}
        rows={5}
      />
    </Grid>

    {!rescheduleOption && (
      <>
        <Grid item xs={12} md={6}>
          <HookFormAutocompleteField
            name="recurFrequency"
            label="Repeat Frequency"
            options={[
              { label: 'None', value: null },
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' }
            ]}
            control={control}
            errors={errors}
            rules={{
              required: false
            }}
            notClearable={true}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {Boolean(selectedRecurFrequency) && (
            <HookFormAutocompleteField
              name="recurInstances"
              label="Total # of Appts"
              options={[
                { label: '1', value: null },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 }
              ]}
              control={control}
              errors={errors}
              rules={{
                required: false
              }}
              notClearable={true}
              disabled={false}
            />
          )}
        </Grid>
      </>
    )}

    {selectedRecurFrequency && (
      <Grid item xs={12} md={12}>
        <Typography color={'red'}>
          Please check that the schedule is available for future appointments.
          By using this feature, you may be booking overlapping appointments.
        </Typography>
      </Grid>
    )}

    {rescheduleOption &&
      appointmentMappings?.getAllowRescheduleOption(tenantIdLowerCase) && (
        <Grid item xs={12} md={12}>
          <HookFormCheckboxField
            name="rescheduleAppointment"
            label={`Record a cancellation count due to reschedule?`}
            control={control}
            errors={errors}
            rules={{
              required: false
            }}
          />
        </Grid>
      )}
  </Grid>
);

// Custom hook for form logic
const useAppointmentForm = (
  control,
  watch,
  setValue,
  getValues,
  serviceOptions
) => {
  const dispatch = useDispatch();

  // Extract form watching logic
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'locationId' && type === 'change') {
        setValue('serviceId', null);
        dispatch(clearFreeAppointmentBookingSlots());
      }

      if (name === 'practitionerId' && type === 'change') {
        setValue('serviceId', null);
        dispatch(clearFreeAppointmentBookingSlots());
      }

      if (name === 'appointmentStartTime' && type === 'change') {
        if (value.serviceId) {
          const serviceDuration =
            serviceOptions.find((service) => service.value === value.serviceId)
              ?.duration || 60;

          const newEndDate = addMinutes(
            value.appointmentStartTime,
            serviceDuration
          );
          setValue('appointmentEndTime', newEndDate);
        }
      }

      if (name === 'serviceId' && type === 'change') {
        onSearchSubmit(new Date());
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, serviceOptions]);

  // Search for available slots
  const onSearchSubmit = (startDate) => {
    const selectedService = getValues('serviceId');
    if (!selectedService) return null;

    const serviceWithDuration = serviceOptions.find(
      (option) => option.value === selectedService && option.duration > 0
    );

    if (serviceWithDuration) {
      const endDate = addDays(lastDayOfMonth(startDate), 1);
      dispatch(
        listFreeAppointmentBookingSlots(
          selectedService,
          format(startDate, 'yyyy-MM-dd'),
          format(endDate, 'yyyy-MM-dd')
        )
      );
    }
  };

  return { onSearchSubmit };
};

// Main component
const CreateAppointmentForm: FC<any> = ({
  control,
  errors,
  watch,
  setValue,
  getValues,
  rescheduleOption = false,
  fromCalendar = false
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const mappedWordsToUse =
    dictionaryMappings?.getMappedWordToUse(tenantIdLowerCase);
  const getMappedWordToUse = (word) => {
    const fallback = word;
    const result = mappedWordsToUse[word];
    return result || fallback;
  };

  const { practitionerTimeOffEvents } = useSelector((state) => state.timeoff);
  const { appointmentFreeSlots, providerSchedule } = useSelector(
    (state) => state.appointments
  );
  const { practitionerClassSchedule } = useSelector(
    (state) => state.classAppointments
  );
  const { activeServicesList } = useSelector((state) => state.services);
  const { activeLocationDetails } = useSelector((state) => state.locations);
  const { loggedInUser, providerOptions } = useSelector((state) => state.users);

  const [reducedHoursShown, setReducedHoursShown] = useState(true);
  const [editFirstTouch, setEditFirstTouch] = useState(true);
  const calendarRef = useRef<FullCalendar | null>(null);
  const [dateValue, setDateValue] = useState(new Date());
  const [filteredPractitioners, setFilteredPractitioners] =
    useState(providerOptions);
  const [serviceOptions, setServiceOptions] = useState([
    { label: '', value: null, duration: 0 }
  ]);

  const locationOptions = activeLocationDetails
    ?.filter((loc) => loggedInUser?.allowedLocationIds?.includes(loc.id))
    ?.map((loc) => ({
      label: loc.name,
      value: loc.id
    }));

  const selectedApptStartTime = useWatch({
    control,
    name: 'appointmentStartTime'
  });

  const selectedApptEndTime = useWatch({
    control,
    name: 'appointmentEndTime'
  });

  const selectedLocationId = useWatch({
    control,
    name: 'locationId'
  });

  const selectedPractitionerId = useWatch({
    control,
    name: 'practitionerId'
  });

  const selectedRecurFrequency = useWatch({
    control,
    name: 'recurFrequency'
  });

  // Use custom hook for form logic
  const { onSearchSubmit } = useAppointmentForm(
    control,
    watch,
    setValue,
    getValues,
    serviceOptions
  );

  // Filter services based on location and practitioner
  const filteredServices = () => {
    // First filter list of services by selected location
    const filteredServicesByLocation = activeServicesList?.filter(
      (service) => service?.locationId === selectedLocationId
    );

    // Get practitioners for this location's services
    const practitionersByServiceLocation = [
      ...new Set(filteredServicesByLocation?.map((item) => item.practitionerId))
    ];

    const filteredPracOptions = providerOptions?.filter((prac) =>
      practitionersByServiceLocation?.includes(prac.value)
    );
    setFilteredPractitioners(filteredPracOptions);

    // If practitioner is not in filtered options list, reset to null
    if (
      !practitionersByServiceLocation?.includes(selectedPractitionerId) &&
      filteredPracOptions?.length > 0
    ) {
      setValue('practitionerId', null);
    }

    // Generate new service options based on location and practitioner
    const filteredServicesByLocationPractitioner =
      filteredServicesByLocation?.filter(
        (service) =>
          service?.practitionerId ===
          (!practitionersByServiceLocation?.includes(selectedPractitionerId)
            ? null
            : selectedPractitionerId)
      );

    const filterServicesOptions = filteredServicesByLocationPractitioner?.map(
      (service) => ({
        label: service.name,
        value: service.id,
        duration: service.duration
      })
    );

    // Reset Service Options to what is available
    setServiceOptions([
      { label: '', value: null, duration: 0 },
      ...filterServicesOptions
    ]);
  };

  // Process free slots for display
  const sgFreeSlots = appointmentFreeSlots
    .filter((timeslot) => isAfter(parseISO(timeslot.startTime), new Date()))
    .map((timeslot) => ({
      ...timeslot,
      startTime: parseISO(timeslot.startTime),
      endTime: parseISO(timeslot.endTime)
    }));

  const uniqueDates = sgFreeSlots.filter(
    (date, i, self) =>
      self.findIndex((d) =>
        isEqual(startOfDay(d.startTime), startOfDay(date.startTime))
      ) === i
  );

  const shouldDisableDate = (date) => {
    return !uniqueDates.find((uniqueDate) =>
      isEqual(startOfDay(uniqueDate.startTime), startOfDay(date))
    );
  };

  // Check practitioner schedule
  const checkPractitionerSchedule = (inputDate) => {
    const twoMonthsLater = addMonths(inputDate, 2);
    dispatch(
      getPractitionerSchedule(
        format(endOfMonth(twoMonthsLater), 'yyyy-MM-dd'),
        format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        getValues('practitionerId')
      )
    );

    dispatch(
      getPractitionerClassSchedule(
        format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        format(endOfMonth(twoMonthsLater), 'yyyy-MM-dd'),
        getValues('practitionerId')
      )
    );

    if (getValues('practitionerId') && getValues('locationId')) {
      dispatch(
        getPractitionerTimeOff(
          [getValues('practitionerId')],
          startOfMonth(new Date()),
          endOfMonth(twoMonthsLater),
          [getValues('locationId')]
        )
      );
    }

    // Reset date to today if practitioner changes
    setDateValue(new Date());
  };

  // Initialize services when data is available
  useEffect(() => {
    if (activeServicesList?.length === 0) {
      return;
    }
    filteredServices();
  }, [activeServicesList]);

  // Update services when location or practitioner changes
  useEffect(() => {
    if (selectedLocationId && providerOptions?.length > 0) {
      filteredServices();
    }
  }, [selectedLocationId, selectedPractitionerId, providerOptions?.length]);

  // Load practitioner schedule when practitioner is selected
  useEffect(() => {
    if (getValues('practitionerId') !== '' && getValues('practitionerId')) {
      checkPractitionerSchedule(new Date());
    }
  }, [getValues('practitionerId'), getValues('locationId')]);

  // For edit form, load free slots if service is selected
  useEffect(() => {
    if (
      getValues('practitionerId') !== '' &&
      getValues('serviceId') !== '' &&
      getValues('practitionerId') &&
      getValues('serviceId')
    ) {
      onSearchSubmit(startOfMonth(selectedApptStartTime));
      setDateValue(selectedApptStartTime);
    }
  }, [serviceOptions]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(clearFreeAppointmentBookingSlots());
      dispatch(clearPractitionerSchedule());
      dispatch(clearPractitionerClassSchedule());
      dispatch(clearPractitionerTimeOffEvents());
    };
  }, []);

  return (
    <Grid container spacing={0}>
      {/* Form Fields Section */}
      <Grid item xs={12} md={12}>
        <FormFieldsSection
          control={control}
          errors={errors}
          locationOptions={locationOptions}
          filteredPractitioners={filteredPractitioners}
          serviceOptions={serviceOptions}
          loggedInUser={loggedInUser}
          getMappedWordToUse={getMappedWordToUse}
        />
      </Grid>

      {/* Calendar Section */}
      <Grid item xs={12} md={6}>
        <CalendarSection
          dateValue={dateValue}
          reducedHoursShown={reducedHoursShown}
          setReducedHoursShown={setReducedHoursShown}
          calendarRef={calendarRef}
          providerSchedule={providerSchedule}
          practitionerClassSchedule={practitionerClassSchedule}
          practitionerTimeOffEvents={practitionerTimeOffEvents}
          isMobile={isMobile}
        />
      </Grid>

      {/* Appointment Details Section */}
      <Grid item xs={12} md={6}>
        <AppointmentDetailsSection
          dateValue={dateValue}
          setDateValue={setDateValue}
          editFirstTouch={editFirstTouch}
          setEditFirstTouch={setEditFirstTouch}
          calendarRef={calendarRef}
          sgFreeSlots={sgFreeSlots}
          selectedApptStartTime={selectedApptStartTime}
          selectedApptEndTime={selectedApptEndTime}
          selectedRecurFrequency={selectedRecurFrequency}
          shouldDisableDate={shouldDisableDate}
          onSearchSubmit={onSearchSubmit}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          serviceOptions={serviceOptions}
          rescheduleOption={rescheduleOption}
          fromCalendar={fromCalendar}
          isMobile={isMobile}
          tenantIdLowerCase={tenantIdLowerCase}
          appointmentMappings={appointmentMappings}
          getMappedWordToUse={getMappedWordToUse}
        />
      </Grid>
    </Grid>
  );
};

export default CreateAppointmentForm;

export const customGetAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      classAppointmentId
      classServiceId
      # classAppointment {
      #  status
      #  classPublicNotes
      # }
      type
      status
      cancellationNote
      cancellationAt
      appointmentNote
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      serviceId
      service {
        id
        name
        category
      }
      locationId
      location {
        id
        name
        code
        taxStatus
      }
      practitionerId
      practitioner {
        id
        firstName
        lastName
        preferredName
        username
      }
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingCustomFields
      onlineBookingTransactionId
      reservationCode
      createdAt
      createdBy
      confirmedAt
      confirmedBy
      updatedAt
      updatedBy
      patientId
      patient {
        id
        fullName
        isInitialized
        customerShortCode
        # location {
        #   id
        #   name
        #   code
        #   addressStreetAddress
        #   addressUnitNo
        #   addressPostalCode
        #   addressCountry
        #   taxStatus
        #   registeredName
        #   registrationNumber
        #   operatingHours
        #   telephones
        #   whatsapp
        #   emails
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          noKnownMedicalConditions
          drugAllergies
          foodAllergies
          otherAllergies
          medicalConditions
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              # officePhone
              # officeFax
              # officeEmail
              # addressStreetAddress
              # addressUnitNo
              # addressCountry
              # addressPostalCode
              # websiteURL
              # note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              # createdAt
              # createdBy
              # updatedAt
              # updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        # marketingProfile {
        #   id
        #   referredBy
        #   referralSource
        #   referralCategory
        #   consent
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const customListPatientAppointmentsQuery = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
    $includeClasses: Boolean
    $classAppointmentIds: [ID!]
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
      includeClasses: $includeClasses
      classAppointmentIds: $classAppointmentIds
    ) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      classAppointmentId
      classServiceId
      # classAppointment {
      #  status
      #  classPublicNotes
      # }
      type
      status
      cancellationNote
      cancellationAt
      appointmentNote
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      serviceId
      service {
        id
        name
        category
      }
      locationId
      location {
        id
        name
        code
        taxStatus
      }
      practitionerId
      practitioner {
        id
        firstName
        lastName
        preferredName
        username
      }
      passId
      patientPassId
      passRedemptionId
      planId
      planMilestone
      onlineBookingCustomFields
      onlineBookingTransactionId
      reservationCode
      createdAt
      createdBy
      confirmedAt
      confirmedBy
      updatedAt
      updatedBy
      patientId
      patient {
        id
        fullName
        isInitialized
        customerShortCode
        # location {
        #   id
        #   name
        #   code
        #   addressStreetAddress
        #   addressUnitNo
        #   addressPostalCode
        #   addressCountry
        #   taxStatus
        #   registeredName
        #   registrationNumber
        #   operatingHours
        #   telephones
        #   whatsapp
        #   emails
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          noKnownMedicalConditions
          drugAllergies
          foodAllergies
          otherAllergies
          medicalConditions
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              # officePhone
              # officeFax
              # officeEmail
              # addressStreetAddress
              # addressUnitNo
              # addressCountry
              # addressPostalCode
              # websiteURL
              # note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              # createdAt
              # createdBy
              # updatedAt
              # updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        # marketingProfile {
        #   id
        #   referredBy
        #   referralSource
        #   referralCategory
        #   consent
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const customListCustomerUpcomingAppointmentsQuery = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
    $includeClasses: Boolean
    $classAppointmentIds: [ID!]
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
      includeClasses: $includeClasses
      classAppointmentIds: $classAppointmentIds
    ) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      serviceId
      classServiceId
      classAppointmentId
      patientId
      locationId
      practitionerId
      type
      status
      planMilestone
      cancellationAt
      createdAt
      createdBy
      confirmedAt
      confirmedBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListFreeAppointmentBookingSlots = /* GraphQL */ `
  query ListFreeAppointmentBookingSlots(
    $startDate: AWSDate!
    $endDate: AWSDate!
    $serviceIds: [ID!]
    $classServiceIds: [ID!]
  ) {
    listFreeAppointmentBookingSlots(
      startDate: $startDate
      endDate: $endDate
      serviceIds: $serviceIds
      classServiceIds: $classServiceIds
    ) {
      # service {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   category
      #   duration
      #   workingHours
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      startTime
      endTime
    }
  }
`;

export const customListPatientAppointmentsReportingQuery = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
    ) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      type
      status
      serviceId
      # service {
      # id
      # name
      # category
      # }
      locationId
      # location {
      # id
      # name
      # code
      # taxStatus
      # }
      practitionerId
      # practitioner {
      # id
      #  firstName
      #  lastName
      # preferredName
      #  username
      # }
      createdAt
      createdBy
      updatedAt
      updatedBy
      patient {
        id
        fullName
        #  piiProfile {
        #    id
        #    fullName
        #    preferredName
        #    identityNumber
        #    identityType
        #  }
      }
    }
  }
`;

export const customListPatientAppointmentsFlowQuery = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
    $includeClasses: Boolean
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
      includeClasses: $includeClasses
    ) {
      id
      appointmentDate
      classAppointmentId
      locationId
      type
      status
    }
  }
`;


export const customGetAppointmentByReservationCode = /* GraphQL */ `
  query GetAppointmentByReservationCode($reservationCode: String!) {
    getAppointmentByReservationCode(reservationCode: $reservationCode) {
      id
      patientId
      patient {
        fullName
        isInitialized
      }
      serviceId
      service {
        id
        name
        code
      }
      locationId
      location {
        id
        name
        code
      }
      practitionerId
      practitioner {
        id
        preferredName
      }
      confirmedAt
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      reservationCode
      appointmentNote
      classAppointmentId
      classServiceId
      createdAt
      createdBy
      updatedAt
      updatedBy

    }
  }
`;
import { FC, ReactNode, useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
// Use this to toggle which login page to use
// import Login from 'src/content/pages/Auth/Login/Cover';
import Login from 'src/content/pages/Auth/Login/Basic';
import { checkForIndexHTMLChange } from './checkForIndexHTMLChange';

interface AuthenticatedProps {
  children: ReactNode;
}

const IndexHTMLURL = 'https://app.gethelm.io/';
let reloadOnNextChange = false;

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const { loggedInUser } = useSelector((state) => state.users);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  // If in prod, detect for changes in app version
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      let checkIndexInterval = setInterval(async () => {
        reloadOnNextChange = await checkForIndexHTMLChange(IndexHTMLURL);
        // console.log(reloadOnNextChange);
        if (reloadOnNextChange) {
          clearInterval(checkIndexInterval);
        }
      }, 1000 * 60 * 60);
    }
  }, []);

  useEffect(() => {
    if (reloadOnNextChange) {
      window.location.reload();
    }
  }, [location]);

  // console.log(loggedInUser)
  // console.log(auth)
  // custom code to log the user out if they are isActive false. we still need to hook up to cognito. this is temp solution.
  if (
    auth.isAuthenticated &&
    loggedInUser?.id !== 'None' &&
    !loggedInUser?.isActive
  ) {
    auth.logout();
  }

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }
  // If only the user type is wrong, push to the right home page
  if (auth.isAuthenticated && auth.user?.type === 'Terminal') {
    return <Navigate to={'/terminal'} />;
  }

  if (auth.isAuthenticated && auth.user?.type === 'Kiosk') {
    return <Navigate to={'/kiosk/kiosk/checkin'} />;
  }

  // console.log(auth)

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;

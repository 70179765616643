import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
// import Login from 'src/content/pages/Auth/Login/Cover';
import Login from 'src/content/pages/Auth/Login/Basic';

interface AuthenticatedProps {
  children: ReactNode;
}

const KioskAuthenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  
  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  // If only the user type is wrong, push to the right home page
  if (auth.isAuthenticated && auth.user?.type !== 'Kiosk') {
    return <Navigate to={'/app/overview/home'} />;
  }

  // console.log(auth)

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

KioskAuthenticated.propTypes = {
  children: PropTypes.node
};

export default KioskAuthenticated;

export const customCreateAppointment = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput) {
    createAppointment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        customerShortCode
        location {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          drugAllergies
          foodAllergies
          otherAllergies
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              officePhone
              officeFax
              officeEmail
              addressStreetAddress
              addressUnitNo
              addressCountry
              addressPostalCode
              websiteURL
              note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        marketingProfile {
          id
          referredBy
          referralSource
          referralCategory
          consent
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        workingHours
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
        firstName
        lastName
        middleName
        jobTitle
        profilePicture
        email
        mobile
        primaryLocationId
        currentLocationId
        allowedLocationIds
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        onboardDate
        resignationDate
        isActive
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      reservationCode
      invoices {
        id
        type
        patientId
        appointmentId
        practitionerId
        locationId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput) {
    updateAppointment(input: $input) {
      id
      patientId
      patient {
        id
        fullName
        customerShortCode
        location {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          drugAllergies
          foodAllergies
          otherAllergies
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              officePhone
              officeFax
              officeEmail
              addressStreetAddress
              addressUnitNo
              addressCountry
              addressPostalCode
              websiteURL
              note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        marketingProfile {
          id
          referredBy
          referralSource
          referralCategory
          consent
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        workingHours
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressPostalCode
        addressCountry
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        firstName
        lastName
        middleName
        preferredName
        jobTitle
        profilePicture
        email
        mobile
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        onboardDate
        resignationDate
        isActive
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      classAppointmentId
      classServiceId
      classAppointment {
        status
        classPublicNotes
        classService {
          name
        }
      }
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passId
      patientPassId
      passRedemptionId
      reservationCode
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateConfirmStatusAppointment = /* GraphQL */ `
  mutation UpdateConfirmStatusAppointment(
    $appointmentId: ID!
    $isConfirmed: Boolean!
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
  ) {
    updateConfirmStatusAppointment(
      appointmentId: $appointmentId
      isConfirmed: $isConfirmed
      newStatus: $newStatus
      timestamp: $timestamp
    ) {
      id
      patientId
      patient {
        id
        fullName
        customerShortCode
        location {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          drugAllergies
          foodAllergies
          otherAllergies
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              officePhone
              officeFax
              officeEmail
              addressStreetAddress
              addressUnitNo
              addressCountry
              addressPostalCode
              websiteURL
              note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        marketingProfile {
          id
          referredBy
          referralSource
          referralCategory
          consent
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        workingHours
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressPostalCode
        addressCountry
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        firstName
        lastName
        middleName
        preferredName
        jobTitle
        profilePicture
        email
        mobile
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        onboardDate
        resignationDate
        isActive
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      classAppointmentId
      classServiceId
      classAppointment {
        status
        classPublicNotes
        classService {
          name
        }
      }
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passId
      patientPassId
      passRedemptionId
      reservationCode
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateCheckInStatusAppointment = /* GraphQL */ `
  mutation UpdateCheckinStatusAppointment(
    $appointmentId: ID!
    $isCheckin: Boolean!
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
  ) {
    updateCheckinStatusAppointment(
      appointmentId: $appointmentId
      isCheckin: $isCheckin
      newStatus: $newStatus
      timestamp: $timestamp
    ) {
      id
      patientId
      patient {
        id
        fullName
        customerShortCode
        location {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          drugAllergies
          foodAllergies
          otherAllergies
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              officePhone
              officeFax
              officeEmail
              addressStreetAddress
              addressUnitNo
              addressCountry
              addressPostalCode
              websiteURL
              note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        marketingProfile {
          id
          referredBy
          referralSource
          referralCategory
          consent
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        workingHours
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressPostalCode
        addressCountry
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        firstName
        lastName
        middleName
        preferredName
        jobTitle
        profilePicture
        email
        mobile
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        onboardDate
        resignationDate
        isActive
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      classAppointmentId
      classServiceId
      classAppointment {
        status
        classPublicNotes
        classService {
          name
        }
      }
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passId
      patientPassId
      passRedemptionId
      reservationCode
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateCancelStatusAppointment = /* GraphQL */ `
  mutation UpdateCancelStatusAppointment(
    $appointmentId: ID!
    $isCancelled: Boolean!
    $cancellationNote: String
    $newStatus: AppointmentStatus
    $timestamp: AWSDateTime
    $unlinkAppointmentPlan: Boolean
    $sendNotification: Boolean
  ) {
    updateCancelStatusAppointment(
      appointmentId: $appointmentId
      isCancelled: $isCancelled
      cancellationNote: $cancellationNote
      newStatus: $newStatus
      timestamp: $timestamp
      unlinkAppointmentPlan: $unlinkAppointmentPlan
      sendNotification: $sendNotification
    ) {
      id
      patientId
      patient {
        id
        fullName
        customerShortCode
        location {
          id
          name
          code
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          taxStatus
          registeredName
          registrationNumber
          operatingHours
          telephones
          whatsapp
          emails
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          drugAllergies
          foodAllergies
          otherAllergies
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              officePhone
              officeFax
              officeEmail
              addressStreetAddress
              addressUnitNo
              addressCountry
              addressPostalCode
              websiteURL
              note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        marketingProfile {
          id
          referredBy
          referralSource
          referralCategory
          consent
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      serviceId
      service {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        workingHours
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      locationId
      location {
        id
        name
        code
        addressStreetAddress
        addressUnitNo
        addressPostalCode
        addressCountry
        taxStatus
        registeredName
        registrationNumber
        operatingHours
        telephones
        whatsapp
        emails
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      practitionerId
      practitioner {
        id
        username
        type
        firstName
        lastName
        middleName
        preferredName
        jobTitle
        profilePicture
        email
        mobile
        timezone
        country
        createdAt
        createdBy
        updatedAt
        updatedBy
        identityNumber
        identityType
        registrationAuthority
        registrationNumber
        discipline
        qualifications
        onboardDate
        resignationDate
        isActive
      }
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      appointmentCheckInTime
      appointmentCompleteTime
      type
      classAppointmentId
      classServiceId
      classAppointment {
        status
        classPublicNotes
        classService {
          name
        }
      }
      status
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      appointmentNote
      invoices {
        id
        type
        patientId
        appointmentId
        invoiceNumber
        issueDate
        dueDate
        reference
        note
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      passId
      patientPassId
      passRedemptionId
      confirmedAt
      confirmedBy
      cancellationAt
      cancellationBy
      cancellationNote
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customTransferAppointment = /* GraphQL */ `
  mutation TransferAppointment(
    $appointmentId: ID!
    $currentCustomerId: ID!
    $newCustomerId: ID!
    $cascadeInvoice: Boolean!
  ) {
    transferAppointment(
      appointmentId: $appointmentId
      currentCustomerId: $currentCustomerId
      newCustomerId: $newCustomerId
      cascadeInvoice: $cascadeInvoice
    ) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      classAppointmentId
      classServiceId
      classAppointment {
        status
        classPublicNotes
        classService {
          name
        }
      }
      type
      status
      cancellationNote
      cancellationAt
      appointmentNote
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      serviceId
      service {
        id
        name
        category
      }
      locationId
      location {
        id
        name
        code
        taxStatus
      }
      practitionerId
      practitioner {
        id
        firstName
        lastName
        preferredName
        username
      }
      passId
      patientPassId
      passRedemptionId
      onlineBookingCustomFields
      createdAt
      createdBy
      confirmedAt
      confirmedBy
      updatedAt
      updatedBy
      patientId
      patient {
        id
        fullName
        isInitialized
        # location {
        #   id
        #   name
        #   code
        #   addressStreetAddress
        #   addressUnitNo
        #   addressPostalCode
        #   addressCountry
        #   taxStatus
        #   registeredName
        #   registrationNumber
        #   operatingHours
        #   telephones
        #   whatsapp
        #   emails
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }
        piiProfile {
          id
          fullName
          preferredName
          identityNumber
          identityType
          birthday
          maritalStatus
          title
          gender
          nationality
          mobileCountryCode
          mobile
          email
          addressStreetAddress
          addressUnitNo
          addressCountry
          addressPostalCode
          telephone2
          telephone3
          occupation
          internalNote
          employer
          nextOfKin
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        medicalProfile {
          id
          noKnownDrugAllergy
          noKnownFoodAllergy
          noKnownOtherAllergy
          noKnownMedicalConditions
          drugAllergies
          foodAllergies
          otherAllergies
          medicalConditions
          vaccines
          insurance
          subsidySchemes
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
              # officePhone
              # officeFax
              # officeEmail
              # addressStreetAddress
              # addressUnitNo
              # addressCountry
              # addressPostalCode
              # websiteURL
              # note
              affectsPricing
              insurancePaymentType
              invoiceNoteTemplate
              validFrom
              validTo
              isActive
              # createdAt
              # createdBy
              # updatedAt
              # updatedBy
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        # marketingProfile {
        #   id
        #   referredBy
        #   referralSource
        #   referralCategory
        #   consent
        #   createdAt
        #   createdBy
        #   updatedAt
        #   updatedBy
        # }

        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

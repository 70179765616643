import { FC, ReactNode, useEffect, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import PinInput from 'w-react-pin-input';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'src/store';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Backdrop,
  Card,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  LinearProgress,
  Typography,
  styled,
  useTheme
} from '@mui/material';

import { getGlobalUISettings } from 'src/slices/settings';
import { getLoggedInUser } from 'src/slices/users';
import { listLocationDetails } from 'src/slices/locations';
import { setIsUnlocked, setLockEnabled } from 'src/slices/terminal';

import Sidebar from './Sidebar';
import Header from './Header';

import tenantMappings from 'src/utils/tenantMappings';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #63666A;
`
);

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const terminalMappings = tenantMappings?.terminalMappings;

const KioskAppLayout: FC<AccentSidebarLayoutProps> = () => {
  const theme = useTheme();
  const inputEl = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const { isUnlocked, lockEnabled } = useSelector((state) => state.terminal);

  const [errorMsg, setErrorMsg] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);

  // const pinCode = '1111';
  const pinCode = terminalMappings?.getTerminalPin(tenantIdLowerCase);

  const onPrompt = () => {
    // Fire a Modal Prompt
    if (isUnlocked === true && openPrompt === false) {
      setOpenPrompt(true);
    }
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    // console.log('idle');
    if (isUnlocked === true) {
      setOpenPrompt(false);
      dispatch(setIsUnlocked(false));
      navigate(`/kiosk/kiosk/checkin`);
    }
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    if (openPrompt === true) {
      setOpenPrompt(false);
    }
  };

  const interruptTimer = () => {
    setOpenPrompt(false);
    idleTimer.reset();
  };

  const idleTimer = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: 1000 * 60 * 10,
    promptTimeout: 1000 * 10
  });

  useEffect(() => {
    dispatch(getGlobalUISettings());
    dispatch(getLoggedInUser());
    dispatch(listLocationDetails());
  }, []);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //         setTimeVal(idleTimer.getRemainingTime());
  //     }, 1000);
  // }, []);

  // Disabling the PIN Lock completely and Idle Timer
  useEffect(() => {
    if (3 > 2) {
      // if (true) {
      // console.log('Disable PIN Lock');
      dispatch(setLockEnabled(false));
      dispatch(setIsUnlocked(true));
      idleTimer.pause();
    }
  }, []);

  return (
    <>
      {isUnlocked ? (
        <>
          <Header />
          <Sidebar />
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              flex: 1,
              display: 'flex',
              pt: `${theme.header.height}`,
              [theme.breakpoints.up('lg')]: {
                pl: 0
              }
              // original codes
              // [theme.breakpoints.up('xl')]: {
              //   pl: `${theme.sidebar.width}`
              // }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Box flexGrow={1}>
                <Outlet />
              </Box>
            </Box>
            {/* <ThemeSettings /> */}
          </Box>
        </>
      ) : (
        <>
          <MainContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh', minWidth: '100vw' }}
              sx={{ alignItems: 'center', alignContent: 'center' }}
            >
              <Box>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h1"
                    sx={{ mb: 3, color: 'white' }}
                  >
                    Enter Pin To Begin
                  </Typography>
                  {errorMsg && (
                    <Typography
                      align="center"
                      variant="h4"
                      sx={{ color: 'red', background: 'white' }}
                    >
                      Incorrect pin
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <PinInput
                    length={4}
                    initialValue=""
                    ref={(n) => (inputEl.current = n)}
                    secret
                    focus
                    onChange={(value, index) => {}}
                    type="numeric"
                    inputMode="numeric"
                    style={{ padding: '10px' }}
                    inputStyle={{
                      borderColor: 'white',
                      fontSize: '40px',
                      height: '70px',
                      width: '70px'
                    }}
                    inputFocusStyle={{ borderColor: 'black' }}
                    onComplete={(value, index) => {
                      // console.log(value);
                      if (value === pinCode) {
                        setErrorMsg(false);
                        dispatch(setIsUnlocked(true));
                      } else {
                        // console.log('please try again');
                        setErrorMsg(true);
                        inputEl.current.clear();
                      }
                    }}
                    autoSelect={false}
                    regexCriteria="[0-9]*"
                    // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </Grid>
              </Box>
            </Grid>
          </MainContent>
        </>
      )}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openPrompt}
      >
        <Dialog
          open={openPrompt}
          onClose={() => interruptTimer()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
          <DialogTitle id="alert-dialog-title">{'Are you there?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please touch the screen if you would like to continue. If not, the
              device will lock in 10 seconds.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => interruptTimer()}
              autoFocus
            >
              Yes I'm still here
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
    </>
  );
};

export default KioskAppLayout;
